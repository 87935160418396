import {Box, Typography} from "@mui/material";
import {NavigationMenu} from "../../components/NavigationMenu";
import React, {useEffect, useState} from "react";
import {DesktopLoanCalculator} from "../../components/loanCalculator/DesktopLoanCalculator";
import {MobileDesktopLoanCalculator} from "../../components/loanCalculator/MobileDesktopLoanCalculator";
import {AutoPlayVideo} from "../../components/AutoPlayVideo";

interface LandingPageCalculatorProps {
    selectedCrypto: string;
    setSelectedCrypto: (selectedCrypto: string)=> void;
}

export const LandingPageCalculator = ({selectedCrypto, setSelectedCrypto}: LandingPageCalculatorProps) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setCurrentWidth(window.innerWidth);
            setIsMobile(window.innerWidth <= 840);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100vh"
            sx={{
                backgroundColor: '#000BFF'
            }}
        >
            <NavigationMenu />
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                width="100%"
                height="100%"
                sx={{backgroundColor: '#000BFF'}}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent={isMobile ? "center" : "left"}
                        alignItems={isMobile ? "center" : "left"}
                        mb={4}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignContent="center"
                            paddingLeft={isMobile ? currentWidth < 400 ? "7%" : "10%" : 0}
                            paddingRight={isMobile ? currentWidth < 400 ? "7%" : "10%" : currentWidth < 890 ? "8%" : "18%"}
                        >
                            <Typography
                                fontSize={isMobile ? 'x-large' : 'xx-large'}
                                align="left"
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    color: '#FAFAFA',
                                }}
                            >
                                Borrow Cash against your Crypto Assets
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent={isMobile ? "center" : "left"}
                        alignItems={isMobile ? "center" : "left"}
                        mb={9}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                isMobile ? (
                                    <MobileDesktopLoanCalculator
                                        sliderColorOverride="#1B2030"
                                        sliderTextColorOverride="#1B2030"
                                        selectedCrypto={selectedCrypto}
                                        setSelectedCrypto={setSelectedCrypto}
                                    />
                                ) : (
                                    <DesktopLoanCalculator
                                        selectedCrypto={selectedCrypto}
                                        setSelectedCrypto={setSelectedCrypto}
                                    />
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                {
                    isMobile ? null : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            overflow="hidden"
                        >
                            <AutoPlayVideo height={400} loop playsInline muted/>
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
};