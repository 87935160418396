import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Box, Button} from "@mui/material";
import logo from "../images/Bitlendr_transparent.svg";

const LATERAL_MARGIN_SIZES = {
    VERY_SMALL: 1,
    SMALL: 2
};

export const NavigationMenu = () => {
    const navigate = useNavigate();
    const [isMobileNavVisible, setMobileNavVisible] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/home');
    };

    const toggleMobileNav = () => {
        setMobileNavVisible(!isMobileNavVisible);
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                paddingTop={LATERAL_MARGIN_SIZES.VERY_SMALL}
                paddingBottom={LATERAL_MARGIN_SIZES.VERY_SMALL}
                sx={{backgroundColor: '#000BFF'}}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    ml={LATERAL_MARGIN_SIZES.SMALL}
                >
                    <Box
                        component="img"
                        src={logo}
                        alt="Bitlendr"
                        sx={{
                            maxHeight: '25px',
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    mr={LATERAL_MARGIN_SIZES.VERY_SMALL}
                >
                    {
                        isMobileView ? (
                            <Button
                                variant="text"
                                onClick={toggleMobileNav}
                                sx={{
                                    fontSize: '25px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'transparent',
                                    border: 'none',
                                    padding: 0,
                                    color: '#E6ECFB',
                                }}
                            >
                                ☰
                            </Button>
                            ) : (
                            <Button
                                onClick={handleClick}
                                variant="contained"
                                sx={{
                                backgroundColor: '#1B2030',
                                color: 'white',
                                maxHeight: '30px',
                                borderRadius: '25px',
                                textTransform: 'none',
                                display: 'flex',
                                flex: 1
                            }}
                            >
                                Get money
                            </Button>
                        )
                    }
                </Box>
            </Box>
            {
                isMobileView && isMobileNavVisible ? (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        width="100%"
                        paddingBottom={LATERAL_MARGIN_SIZES.VERY_SMALL}
                        paddingTop={LATERAL_MARGIN_SIZES.VERY_SMALL}
                        sx={{
                            backgroundColor: '#000BFF',
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="100%"
                            ml={LATERAL_MARGIN_SIZES.SMALL}
                            mr={LATERAL_MARGIN_SIZES.SMALL}
                            sx={{
                                backgroundColor: '#1B2030',
                                borderRadius: '8px'
                            }}
                        >
                            <Button
                                size="large"
                                onClick={handleClick}
                                variant="contained"
                                sx={{
                                    marginTop: LATERAL_MARGIN_SIZES.VERY_SMALL,
                                    marginBottom: LATERAL_MARGIN_SIZES.VERY_SMALL,
                                    width: 'fit-content',
                                    height: '28px',
                                    borderRadius: '8px',
                                    color: 'white',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'none',
                                }}>
                                Get Money
                            </Button>
                        </Box>
                    </Box>
                ) : null
            }
        </>
    );
};