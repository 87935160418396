import React, {useEffect, useState} from 'react';
import {SideMenu, SideMenuItems} from '../../components/sideMenu/SideMenu';
import {InAppHeader} from '../../components/inAppHeader/InAppHeader';
import {OnboardingForm} from '../../components/onboardingForm/OnboardingForm';
import {Box, Container} from '@mui/material';


export const OnboardPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 840);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            minHeight="100vh"
            height="100%"
            sx={{
                justifyContent: 'center',
                backgroundColor: '#E5ECFB'
            }}
        >
            <Container>
                {
                    isMobile ? null : <SideMenu initialItem={SideMenuItems.CALCULATOR} />
                }
                <InAppHeader
                    title={'Onboarding form'}
                    header="Get your loan"
                    subtitle=""
                    isMobile={isMobile}
                    mbOverrides={{
                        mobile: 4,
                        normal: 4
                    }}
                />
                <OnboardingForm
                    isMobile={isMobile}
                    labelTextColor="#9D9D9D"
                />
            </Container>
        </Box>
    );
};