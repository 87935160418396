import React, {useEffect, useState} from 'react';
import {Footer} from "../../components/Footer";
import {LandingPageCalculator} from "./LandingPageCalculator";
import {LandingPageSecondCalculator} from "./LandingPageSecondCalculator";
import {LandingPageBallsAnimation} from "./LandingPageBallsAnimation";
import {LandingPageBoxesPage} from "./LandingPageBoxesPage";

import starShieldIcon from '../../images/starShieldIcon.png';

const PARAGRAPH1 = "Don't sell your crypto! Use it as collateral to get instant access to cash while keeping your assets intact. Perfect for short-term needs or long-term holders who want liquidity.";
const PARAGRAPH2 = "Borrow at highly competitive, low-interest rates compared to traditional loans. Keep your debt manageable and enjoy the advantages of using your assets without heavy fees.";
const PARAGRAPH3 = "Get cash fast with a streamlined, secure application process. Receive fiat directly to your bank account, all while your assets stay securely stored as collateral.";
const PARAGRAPH4 = "Keep your loan as long as you need—no fixed end date. Repay on your terms, with the freedom to manage your loan timeline and financial plans without pressure.";
export const LandingPage = () => {
    const [selectedCrypto, setSelectedCrypto] = useState<string>('BTC');

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 840);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <LandingPageCalculator selectedCrypto={selectedCrypto} setSelectedCrypto={setSelectedCrypto} />
            <LandingPageBoxesPage
                title="Seize Opportunities Without Selling"
                boxContents={[
                    {
                        imageUrl: 'https://cdn-icons-png.flaticon.com/512/2272/2272825.png',
                        title: 'Unlock liquidity without selling',
                        paragraphText: PARAGRAPH1
                    },
                    {
                        imageUrl: starShieldIcon,
                        title: 'Low-Interest Rates',
                        paragraphText: PARAGRAPH2
                    },
                    {
                        imageUrl: 'https://store-images.s-microsoft.com/image/apps.23613.809078c6-d25f-47b7-866d-bb07c3b4e6d9.06078c3b-2162-4d1c-8d20-4d901444412e.75165666-f7b0-4b6a-84b2-7040dc23122d.png',
                        title: 'Fast and Secure Process',
                        paragraphText: PARAGRAPH3
                    },
                    {
                        imageUrl: 'https://cdn-icons-png.flaticon.com/512/8055/8055582.png',
                        title: 'Perpetual Loan Flexibility',
                        paragraphText: PARAGRAPH4
                    },
                ]}
            />
            {
                isMobile ? null : (
                    <LandingPageSecondCalculator selectedCrypto={selectedCrypto} setSelectedCrypto={setSelectedCrypto} />
                )
            }
            <LandingPageBallsAnimation setSelectedCrypto={setSelectedCrypto} />
            <Footer />
        </>
    );
};
