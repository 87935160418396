import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
// import {generateClient, get} from 'aws-amplify/api';
import {generateClient} from 'aws-amplify/api';
import {createLoan} from "../../graphql/mutations";
import {Status} from '../types';
import {MobileMenuBar} from "../menu/MobileMenuBar";
import {Box, Button, Grid, Typography} from "@mui/material";
import {getWalletAddressForCoin} from "./hardcodedWallets";
import {CardVerticalDividerRow} from "../../baseComponents/cardElements/CardVerticalDividerRow";
import {CardKeyValueRow} from "../../baseComponents/cardElements/CardKeyValueRow";

const client = generateClient();

const NA_STRING = 'N.A.';

// interface WalletInfo {
//     address: string;
//     publicKey: string;
// }

interface ReviewOrderProps {
    isMobile: boolean;
    topLabelTextColorOverride?: string;
    topLabelSecondaryTextColorOverride?: string;
    labelTextColorOverride?: string;
}

export const ReviewOrder = ({
    isMobile,
    topLabelTextColorOverride,
    topLabelSecondaryTextColorOverride,
    labelTextColorOverride
}: ReviewOrderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        cryptoAmount,
        quantity,
        selectedCrypto,
        selectedCurrency,
        paymentMethod,
        iban,
        revolutUsername,
        beneficiaryName,
        beneficiaryAddress,
        swiftCode,
        selectedLTV,
        referralCode
    } = location.state || {};


    const handleClickBack = () => {
        navigate('/onboard', {
            state: {
                ...location.state
            }
        });
    };

    const handleClickReceiptManual = async () => {
        // below -> POC for wallet generation -- commented out for now, as per request from Sergiu
        // let walletInfo: WalletInfo | null = null;
        // try {
        //     const walletGenerateRestOperation = get({
        //         apiName: 'valletGeneration',
        //         path: '/generateWallet'
        //     });
        //     const walletGenerationResponse = await walletGenerateRestOperation.response;
        //     const newWalletInfo = await walletGenerationResponse.body.json() as {[key: string]: string};
        //     if (newWalletInfo && newWalletInfo.address && newWalletInfo.publicKey) {
        //         walletInfo = {
        //             address: newWalletInfo.address,
        //             publicKey: newWalletInfo.publicKey
        //         };
        //     }
        // } catch (error) {
        //     // @ts-ignore
        //     console.error(`Did not manage to generateWallet: ${error.message}`);
        //     return;
        // }
        //
        // if (!walletInfo) {
        //     console.error('Could not generate wallet for loan...');
        //     return;
        // }

        const walletAddress = getWalletAddressForCoin(selectedCrypto);

        try {
            const createResponse = await client.graphql({
                query: createLoan,
                variables: {
                    input: {
                        loanAmount: String(quantity),
                        loanCurrency: String(selectedCurrency),
                        collateralAmount: String(cryptoAmount),
                        collateralCryptoCurrency: String(selectedCrypto),
                        interestAmount: String((quantity * 0.08 / 52).toFixed(2)),
                        interestCurrency: String(selectedCurrency),
                        healthFactor: '694.20',
                        beneficiaryIban: iban,
                        beneficiarySwiftCode: swiftCode,
                        beneficiaryName: beneficiaryName,
                        beneficiaryAddress: beneficiaryAddress,
                        loanWalletAddress: walletAddress,
                        loanWalletPublicKey: NA_STRING,
                        status: Status.PENDING,
                        referralCode
                    }
                },
                authMode: 'userPool'
            });

            console.log(`Create response was: [${JSON.stringify(createResponse)}].`)

            navigate('/receipt', {
                state: {
                    ...location.state,
                    manualTransfer: true,
                    depositAddress: walletAddress,
                    depositPublicKey: NA_STRING,
                }
            });
        } catch (error) {
            console.error(`Error occurred while trying to save the loan details: [${JSON.stringify(error)}].`)
        }
    };

    // const handleClickReceiptCoinbase = () => {
    //     navigate('/receipt', {
    //         state: {
    //         }
    //     });
    // };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={isMobile ? 11 : 8}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    sx={{
                        background: '#FAFAFA',
                        padding: '20px',
                        borderRadius: '20px',
                    }}
                >
                    {/* TOP LINES */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                        mb={3}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                mt={isMobile ? 0 : 1}
                                alignSelf="center"
                                color={topLabelTextColorOverride ?? '#FAFAFA'}
                                fontSize={isMobile ? 'xx-small' : 'small'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                FOR
                            </Typography>
                            <Typography
                                alignSelf="center"
                                color={topLabelTextColorOverride ?? '#FAFAFA'}
                                fontSize={isMobile ? 'large' : 'xx-large'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                {cryptoAmount} {selectedCrypto}
                            </Typography>
                            <Typography
                                mt={isMobile ? 2 : 1}
                                alignSelf="center"
                                color={topLabelTextColorOverride ?? '#FAFAFA'}
                                fontSize={isMobile ? 'xx-small' : 'small'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                YOU WILL GET
                            </Typography>
                            <Typography
                                alignSelf="center"
                                color={topLabelSecondaryTextColorOverride ?? '#FAFAFA'}
                                fontSize={isMobile ? 'large' : 'xx-large'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                {quantity} {selectedCurrency}
                            </Typography>
                            <Typography
                                mt={isMobile ? 2 : 1}
                                alignSelf="center"
                                color={topLabelTextColorOverride ?? '#FAFAFA'}
                                fontSize={isMobile ? 'xx-small' : 'small'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                LOAN TO VALUE
                            </Typography>
                            <Typography
                                alignSelf="center"
                                color={topLabelTextColorOverride ?? '#FAFAFA'}
                                fontSize={isMobile ? 'large' : 'xx-large'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                {selectedLTV * 100} %
                            </Typography>
                        </Box>
                    </Box>

                    <CardVerticalDividerRow />

                    <CardKeyValueRow
                        keyText="IBAN"
                        valueText={iban}
                        marginTop={2}
                    />

                    <CardKeyValueRow
                        keyText="NAME"
                        valueText={beneficiaryName}
                        marginTop={2}
                    />

                    <CardKeyValueRow
                        keyText="ADDRESS"
                        valueText={beneficiaryAddress}
                        marginTop={2}
                    />

                    <CardKeyValueRow
                        keyText="SWIFT"
                        valueText={swiftCode}
                        marginTop={2}
                    />

                    {/* Buttons */}
                    <Box display="flex" flexDirection="row" minWidth="fit-content">
                        <Button
                            size="large"
                            onClick={handleClickBack}
                            variant="contained"
                            sx={{
                                height: '45px',
                                borderRadius: '50px',
                                backgroundColor: '#1a202c',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                marginLeft: 'auto',
                                marginRight: '10px',
                                marginTop: '20px',
                                marginBottom: '-40px',
                            }}>
                            {`← Back`}
                        </Button>
                        <Button
                            size="large"
                            onClick={handleClickReceiptManual}
                            variant="contained"
                            sx={{
                                height: '45px',
                                borderRadius: '50px',
                                backgroundColor: '#0752FF',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                marginLeft: '10px',
                                marginRight: 'auto',
                                marginTop: '20px',
                                marginBottom: '-40px',
                            }}>
                            {`Apply  →`}
                        </Button>
                    </Box>
                </Box>

            </Grid>
            {
                isMobile ? <MobileMenuBar/> : null
            }
        </Grid>
    );
};