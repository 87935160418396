import React, {useCallback, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Slider,
    Typography,
    SelectChangeEvent,
    Icon,
    CircularProgress,
    Backdrop
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
    convertPriceQuotes,
    ListCryptoPriceQuotesResponse,
    ExchangeRates,
    PriceQuotes,
    ListCurrencyExchangeRatesResponse,
    convertExchangeRates
} from '../../data/loanMetrics';
import {generateClient} from 'aws-amplify/api';
import {listCryptoPriceQuotes, listCurrencyExchangeRates} from '../../graphql/queries';
import {CustomSimpleNumberInput, ThousandsFormatNumberInput} from '../../baseComponents/inputs/CustomNumberInputs';

const client = generateClient();

export interface DesktopLoanCalculatorProps {
    inputFieldBackgroundColorOverride?: string;
    iconColorOverride?: string;
    sliderColorOverride?: string;
    sliderTextColorOverride?: string;
    buttonTextOverride?: string;
    selectedCrypto: string;
    setSelectedCrypto: (selectedCrypto: string)=> void;
}

export const DesktopLoanCalculator = ({
    inputFieldBackgroundColorOverride,
    iconColorOverride,
    sliderColorOverride,
    sliderTextColorOverride,
    buttonTextOverride,
    selectedCrypto,
    setSelectedCrypto,
}: DesktopLoanCalculatorProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedLTV, setSelectedLTV] = useState<number>(0.8);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({});
    const [priceQuotes, setPriceQuotes] = useState<PriceQuotes>({});
    const [cryptoAmount, setCryptoAmount] = useState<number | ''>(0); // Added state for crypto amount
    const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');
    const [cashAmount, setCashAmount] = useState<number>(0);
    const navigate = useNavigate();

    const handleGetLoanClick = () => {
        navigate('/home');
    };

    const handleCurrencyChange = (event: SelectChangeEvent) => {
        setSelectedCurrency(event.target.value);

        const cryptoAmountToUse = cryptoAmount === '' ? 0 : cryptoAmount;
        const newCurrencyValue = (cryptoAmountToUse * priceQuotes[selectedCrypto] * exchangeRates[event.target.value] * selectedLTV).toFixed(2);
        setCashAmount(Number(newCurrencyValue));
    };

    const handleCurrencyAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = Number(event.target.value);
        setCashAmount(newQuantity);
        const newCryptoValue = (newQuantity *  ( 1 / selectedLTV ) / (priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency])).toFixed(2);
        setCryptoAmount(Number(newCryptoValue));
    };

    const handleCryptoChange = (event: SelectChangeEvent) => {
        setSelectedCrypto(event.target.value);

        const cryptoAmountToUse = cryptoAmount === '' ? 0 : cryptoAmount;
        const newCurrencyValue = (cryptoAmountToUse * priceQuotes[event.target.value] * exchangeRates[selectedCurrency] * selectedLTV ).toFixed(2);
        setCashAmount(Number(newCurrencyValue));
    };

    const handleCryptoAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newCryptoAmount = 0;

        if (event.target.value === '') {
            setCryptoAmount('');
        } else {
            newCryptoAmount = Number(event.target.value);
            setCryptoAmount(newCryptoAmount);
        }

        const newCurrencyValue = (newCryptoAmount * priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency] * selectedLTV).toFixed(2);
        setCashAmount(Number(newCurrencyValue));
    };

    const handleLTVChange = (value: number | number[]) => {
        const newSelectedLTV = Array.isArray(value) ? value[0] : value;
        setSelectedLTV(newSelectedLTV);

        const cryptoAmountToUse = cryptoAmount === '' ? 0 : cryptoAmount;
        const newCurrencyValue = (cryptoAmountToUse * priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency] * newSelectedLTV).toFixed(2);
        setCashAmount(Number(newCurrencyValue));
    };

    const loadData = useCallback(async () => {
        try {
            const cryptoPriceQuotesResponse: ListCryptoPriceQuotesResponse = await client.graphql({
                query: listCryptoPriceQuotes,
                authMode: 'apiKey'
            }) as ListCryptoPriceQuotesResponse;

            setPriceQuotes(convertPriceQuotes(cryptoPriceQuotesResponse.data.listCryptoPriceQuotes.items));

            const exchangeRatesResponse: ListCurrencyExchangeRatesResponse = await client.graphql({
                query: listCurrencyExchangeRates,
                authMode: 'apiKey'
            }) as ListCurrencyExchangeRatesResponse;

            setExchangeRates(convertExchangeRates(exchangeRatesResponse.data.listCurrencyExchangeRates.items));
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }
        setCryptoAmount(1);

        setIsLoading(false);
    }, []);

    useEffect(() => {
        const cryptoAmountToUse = cryptoAmount === '' ? 0 : cryptoAmount;
        setCashAmount(
            Number(
                Number(
                    cryptoAmountToUse * (priceQuotes[selectedCrypto] ?? 0) * (exchangeRates[selectedCurrency] ?? 0) * selectedLTV
                ).toFixed(2)
            )
        );
    }, [selectedCurrency, selectedCrypto, priceQuotes, exchangeRates, selectedLTV, cryptoAmount]);

    useEffect(() => {
        loadData();
    }, []);


    return (
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={5}>
                    {/* Crypto input */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                            minWidth: '140px',
                            maxWidth: '240px',
                            marginRight: '1rem',
                        }}
                    >
                        <Typography
                            mb={1}
                            justifyContent="left"
                            color={sliderTextColorOverride ?? '#FAFAFA'}
                            fontSize='medium'
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            For
                        </Typography>
                        <FormControl
                            variant="filled"
                            sx={{
                                width: '100%',
                                borderRadius: '50px',
                                overflow: 'hidden',
                                backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB'
                            }}
                        >
                            <Box display="flex" margin={0.5} >
                                <CustomSimpleNumberInput
                                    value={cryptoAmount}
                                    onChangeHandler={handleCryptoAmountChange}
                                    inputFieldBackgroundColorOverride={inputFieldBackgroundColorOverride}
                                />
                                <Select
                                    value={selectedCrypto}
                                    onChange={handleCryptoChange}
                                    disableUnderline
                                    sx={{
                                        flex: '0 0 auto',
                                        minWidth: '80px',
                                        maxWidth: '85px',
                                        background: '#CFDAF2',
                                        color: '#0652ff',
                                        alignItems: 'center',
                                        borderRadius: '50px',
                                        '.MuiInputBase-input': {
                                            marginTop: '-14px',
                                            '&:hover': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                >
                                    {Object.keys(priceQuotes).map((crypto) => (
                                        <MenuItem key={crypto} value={crypto}>
                                            {crypto}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </FormControl>
                    </Box>

                    <Box display="flex" flexDirection="column" >
                        <Box mb={4} />
                        <Icon component={SwapHorizIcon} sx={{color: iconColorOverride ?? '#FAFAFA'}} />
                    </Box>

                    {/* Currency input */}
                    <Box
                        sx={{
                            minWidth: '140px',
                            maxWidth: '240px',
                            marginLeft: '1rem'
                        }}
                    >
                        <Typography
                            mb={1}
                            justifyContent="left"
                            color={sliderTextColorOverride ?? '#FAFAFA'}
                            fontSize='medium'
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            You will get
                        </Typography>
                        <FormControl
                            variant="filled"
                            sx={{
                                width: '100%',
                                borderRadius: '50px',
                                overflow: 'hidden',
                                backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB'
                            }}
                        >
                            <Box display="flex" margin={0.5}>
                                <ThousandsFormatNumberInput
                                    value={cashAmount}
                                    onChangeHandler={handleCurrencyAmountChange}
                                    inputFieldBackgroundColorOverride={inputFieldBackgroundColorOverride}
                                />
                                <Select
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    disableUnderline
                                    sx={{
                                        flex: '0 0 auto',
                                        minWidth: '80px',
                                        maxWidth: '85px',
                                        background: '#CFDAF2',
                                        color: '#0652ff',
                                        alignItems: 'center',
                                        borderRadius: '50px',
                                        '.MuiInputBase-input': {
                                            marginTop: '-14px',
                                            '&:hover': {
                                                outline: 'none',
                                            },
                                        },
                                    }}
                                >
                                    {Object.keys(exchangeRates).map((currency) => (
                                        <MenuItem key={currency} value={currency}>
                                            {currency}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </FormControl>
                    </Box>
                </Box>

                <Typography
                    mb={2}
                    justifyContent="left"
                    color={sliderTextColorOverride ?? '#FAFAFA'}
                    fontSize='medium'
                    sx={{
                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                    }}
                >
                    Loan to value
                </Typography>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                    <Slider
                        marks={[{value: 0.5, label: '50%'},{value: 0.6, label: '60%'},{value: 0.7, label: '70%'},{value: 0.8, label: '80%'}]}
                        min={0.5}
                        max={0.8}
                        step={0.1}
                        valueLabelDisplay="off"
                        value={selectedLTV}
                        onChange={(event, newValue) => handleLTVChange(newValue)}
                        sx={{
                            marginBottom: '60px',
                            width: '100%',
                            color: sliderColorOverride ?? '#CFDAF2',
                            '& .MuiSlider-mark': {
                                width: '16px',
                                height: '16px',
                                borderRadius: '50%',
                                backgroundColor: 'currentcolor',
                            },
                            '& .MuiSlider-thumb': {
                                marginLeft: '6px',
                                width: '18px',
                                height: '18px',
                                borderRadius: '50%',
                                backgroundColor: '#FFFFFF',
                                border: '3px solid black',
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: '#0752FF',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                },
                                '&:hover, &.Mui-focusVisible': {
                                    boxShadow: 'inherit',
                                }
                            },
                            '& .MuiSlider-markLabel': {
                                top: '44px',
                                color: sliderTextColorOverride ?? '#FFFFFF',
                                marginLeft: '10px'
                            },
                        }}
                    />
                    <Button
                        size="large"
                        onClick={handleGetLoanClick}
                        variant="contained"
                        sx={{
                            height: '45px',
                            borderRadius: '50px',
                            backgroundColor: '#1B2030',
                            whiteSpace: 'nowrap',
                            textTransform: 'none',
                        }}>
                        {buttonTextOverride ?? 'Get this loan'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};