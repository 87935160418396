import React, {useCallback, useEffect, useState} from 'react';
import {SideMenu, SideMenuItems} from '../../components/sideMenu/SideMenu';
import {InAppHeader} from '../../components/inAppHeader/InAppHeader';
import {LoanListItem} from '../../components/loanListItem/LoanListItem';
import { generateClient } from 'aws-amplify/api';
import {listLoans, listSettlementBankAccounts} from '../../graphql/queries';
import {Status} from '../../components/types';
import {Backdrop, Box, CircularProgress, Container, Grid} from '@mui/material';
import {MobileMenuBar} from '../../components/menu/MobileMenuBar';
import {SettlementBankAccount} from '../../data/bankAccounts';

const client = generateClient();

export interface LoanItemData {
    id: string;
    loanAmount: string;
    loanCurrency: string;
    collateralAmount: string;
    collateralCryptoCurrency: string;
    interestAmount: string;
    interestCurrency: string;
    healthFactor: string;
    createdAt: string;
    updatedAt: string;
    owner: string;
    status: Status;
    beneficiaryIban?: string;
    beneficiarySwiftCode?: string;
    beneficiaryName?: string;
    beneficiaryAddress?: string;
    loanWalletAddress?: string;
    loanWalletPublicKey?: string;
    approvedAt?: string;
}

interface ListLoansResponse {
    data: {
        listLoans: {
            items: LoanItemData[];
        };
    };
}

interface ListSettlementBankAccountsResponse {
    data: {
        listSettlementBankAccounts: {
            items: SettlementBankAccount[]
        }
    }
}

interface SettlementBankAccountMapping {
    [currency: string]: SettlementBankAccount
}

const SORTING_PRIORITY: {[status: string]: number} = {
    [Status.PENDING]: 1,
    [Status.APPROVED]: 2,
    [Status.CLOSING]: 3,
    [Status.CLOSED]: 4,
    [Status.REJECTED]: 5,
};

export const LoanList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    const [needsMargin, setNeedsMargin] = useState(window.innerWidth <= 1350);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 840);
            setNeedsMargin(window.innerWidth <= 1350)
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [loans, setLoans] = useState<LoanItemData[]>([]);
    const [settlementBankAccountsForCurrency, setSettlementBankAccountsForCurrency] = useState<SettlementBankAccountMapping | null>(null);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const listLoansResult: ListLoansResponse = await client.graphql({
                query: listLoans,
                authMode: 'userPool'
            }) as ListLoansResponse;
            setLoans(listLoansResult.data.listLoans.items);

            const listSettlementBankAccountsResponse: ListSettlementBankAccountsResponse = await client.graphql({
                query: listSettlementBankAccounts,
                authMode: "userPool"
            }) as ListSettlementBankAccountsResponse;
            setSettlementBankAccountsForCurrency(
                listSettlementBankAccountsResponse.data.listSettlementBankAccounts.items
                    .reduce(
                        (accumulator, currentBankAccount) => {
                            accumulator[currentBankAccount.currency] = currentBankAccount
                            return accumulator;
                        },
                        {} as SettlementBankAccountMapping
                    ))
            ;
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }

        setIsLoading(false);
    }, [setLoans, setIsLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            minHeight="100vh"
            height="100%"
            sx={{
                justifyContent: 'center',
                backgroundColor: '#E5ECFB'
            }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                {
                    isMobile ? null : <SideMenu initialItem={SideMenuItems.LOANS} />
                }
                <InAppHeader
                    title={'My Loans'}
                    header=""
                    subtitle="This is an overview of your loan portfolio, offering you the flexibility to effortlessly settle existing loans, acquire new ones, and capitalize on your idle digital assets."
                    isMobile={isMobile}
                    mbOverrides={{
                        mobile: 4,
                        normal: 4
                    }}
                />
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    marginLeft={isMobile ? undefined : !needsMargin ? undefined : '70px'}
                    paddingRight={isMobile ? undefined : !needsMargin ? undefined : '90px'}
                >
                    {
                        // @ts-ignore
                        loans.sort((a, b) => {
                            const statusComparison = SORTING_PRIORITY[a.status] - SORTING_PRIORITY[b.status];

                            if (statusComparison === 0) {
                                return b.createdAt.localeCompare(a.createdAt);
                            }

                            return statusComparison;
                        }).map((loan) => (
                            <LoanListItem
                                id={loan.id}
                                date={loan.approvedAt ? loan.approvedAt : loan.updatedAt}
                                loanAmount={Number(loan.loanAmount)}
                                loanCurrency={loan.loanCurrency}
                                collateralAmount={Number(loan.collateralAmount)}
                                collateralCryptoCurrency={loan.collateralCryptoCurrency}
                                interestAmount={Number(loan.interestAmount)}
                                interestCurrency={loan.interestCurrency}
                                status={loan.status}
                                loanWalletAddress={loan.loanWalletAddress}
                                loanWalletPublicKey={loan.loanWalletPublicKey}
                                isMobile={isMobile}
                                onCloseCallback={fetchData}
                                settlementBankAccount={
                                    settlementBankAccountsForCurrency && settlementBankAccountsForCurrency[loan.loanCurrency]
                                        ? settlementBankAccountsForCurrency[loan.loanCurrency]
                                        : null
                                }
                            />
                        ))
                    }
                </Grid>
                {
                    !isMobile ? null : (
                        <Grid container spacing={2} justifyContent="center">
                            <MobileMenuBar/>
                        </Grid>
                    )
                }
            </Container>
        </Box>
    );
};