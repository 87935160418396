import React, {useEffect, useRef, useState} from "react";
import {Input} from "@mui/material";

interface CustomNumberInputProps {
    value: number | '';
    onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputFieldBackgroundColorOverride?: string;
}

const formatValue  = (givenValue: string): string => {
    if (givenValue === "") {
        return '';
    }

    const parts = givenValue.split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to integer part

    if (givenValue.endsWith('.')) {
        return `${parts[0]}.`;
    }

    return parts.join('.');
};
const removeFormattingFromValue = (formattedValue: string): string => {
    return formattedValue.replace(/,/g, '');
};

export const ThousandsFormatNumberInput = ({value, onChangeHandler, inputFieldBackgroundColorOverride}: CustomNumberInputProps) => {
    const [displayValue, setDisplayValue] = useState('');
    useEffect(() => {
        setDisplayValue(
            formatValue(
                String(value)
            )
        );
    }, [value]);

    const inputRef = useRef(null);

    const customOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        const rawValue = removeFormattingFromValue(inputValue);

        const newFormattedValueLength = formatValue(rawValue).length;
        if (value !== Number(rawValue)) {
            onChangeHandler({
                ...event,
                target: {
                    ...event.target,
                    value: rawValue
                }
            });
        } else {
            setDisplayValue(formatValue(rawValue));
        }
    };

    return (
        <Input
            ref={inputRef}
            value={displayValue}
            onChange={customOnChangeHandler}
            inputProps={{ inputMode: 'decimal', pattern: '[0-9.]*' }} // Restrict input to numbers and decimal
            disableUnderline
            sx={{
                flex: 1,
                marginLeft: '14px',
                '&:focus': {
                    outline: 'none', // Remove the focus outline
                },
                '.MuiInputBase-input': {
                    backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB',
                    fontWeight: 'bold', // Make the text bold
                    marginTop: '4px',
                },
                // Hide the default increment/decrement buttons for number input
                'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                'input[type=number]': {
                    '-moz-appearance': 'textfield', // For Firefox
                },
            }}
        />
    );
};

export const CustomSimpleNumberInput = ({value, onChangeHandler, inputFieldBackgroundColorOverride}: CustomNumberInputProps) => {
    return (
        <Input
            type="number"
            value={value}
            onChange={onChangeHandler}
            disableUnderline
            sx={{
                flex: 1,
                marginLeft: '14px',
                '&:focus': {
                    outline: 'none', // Remove the focus outline
                },
                '.MuiInputBase-input': {
                    backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB',
                    fontWeight: 'bold', // Make the text bold
                    marginTop: '4px',
                },
                // Hide the default increment/decrement buttons for number input
                'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                'input[type=number]': {
                    '-moz-appearance': 'textfield', // For Firefox
                },
            }}
        />
    );
};