import {Box, Typography} from "@mui/material";
import React from "react";

interface CardSubtitleRowProps {
    titleText: string;
    marginTop?: number
}

export const CardSubtitleRow = ({titleText, marginTop = 0}: CardSubtitleRowProps) => {
    return (
        <Box
            mt={marginTop}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="90%"
        >
            <Typography
                color="#9D9D9D"
                fontSize="small"
                fontWeight="bold"
                sx={{
                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                }}
            >
                {titleText}
            </Typography>
        </Box>
    );
};