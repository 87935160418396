import React from 'react';
import { List, ListItem, ListItemText, Container, Typography } from '@mui/material'; // Assuming you're using MUI components

const Policy = () => {
    return (
        <Container>
            <Typography variant="h2">Privacy Policy</Typography>

            <Typography variant="subtitle1" paragraph>
            Last updated: October 8, 2024<br/><br/>
            </Typography>

            <Typography variant="body1">
            This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use this service (Bitlendr.io) and tells You about your privacy rights and how the law protects you.
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            We use your personal data to provide and improve the service.
            <br/><br/>
            </Typography>

            <Typography variant="h3">Interpretation and Definitions<br/><br/>
            </Typography>

            <Typography variant="h4">Interpretation<br/><br/>
            </Typography>

            <Typography variant="body1">
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            <br/><br/>
            </Typography>

            <Typography variant="h4">Definitions<br/><br/>
            </Typography>

            <Typography variant="body1">
            For the purposes of these Terms and Conditions:
            <List>
                <ListItem>
                    <ListItemText primary="Country refers to: Romania" />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"Company (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to SC BorrowBit SRL"}/>
                </ListItem>

                <ListItem>
                    <ListItemText primary="Device means any device that can access the Service such as a computer, a cellphone or a digital tablet." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Service refers to the Website (i.e., www.bitlendr.io)." />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"Terms and Conditions (also referred as \"Terms\") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service."} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Third-party Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Website refers to Bitlendr, accessible from bitlendr.io" />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Interest means the indicated interest rate accrued in accordance with these Terms and subject to revision at any time." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Intellectual Property means any intellectual and industrial property rights, belonging to Bitlendr, for example, trademarks, service marks, logos, copyright and related rights, know-how, research, publications, agreements, trade and company names, etc" />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Digital Assets means any digital assets (such as cryptocurrencies, stablecoins and tokenized assets), accepted by Bitlendr." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="You or Client means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="Loan means any loan facility granted by Bitlendr and the total amount of the loan due by the Client to Bitlendr at any time until its full repayment, including the principal, the Interest and any fees due to Bitlendr under this Agreement." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="The annual percentage rate (hereinafter “the APR”) is the interest rate of return earned." />
                </ListItem>

                <ListItem>
                        <ListItemText primary={"Interest (referred to as either \“Reward\” or \“APR\” in this Agreement) means the indicated interest rate accrued in accordance with these Terms and subject to revision at any time."} />
                </ListItem>

                <ListItem>
                        <ListItemText primary="Active Deposit - it is a Deposit that contains at least the Minimum amount of Digital Assets." />
                </ListItem>
            </List>

            </Typography>
            <Typography variant="h3">Collecting and Using Your Personal Data
            <br/><br/>
            </Typography>


            <Typography variant="h4">Types of Data Collected Personal Data
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to: phone number, bank details, name, address, crypto wallet, usage data.
            <br/><br/>
            </Typography>
            
            <Typography variant="h4">Usage Data
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            Usage Data is collected automatically when using the Service.
            <br/><br/>
            Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
            <br/><br/>
            When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
            <br/><br/>
            We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
            <br/><br/>
            </Typography>

            <Typography variant="h4">Tracking Technologies and Cookies
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include: browser cookies, flash cookies, web beacons.
            <br/><br/>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: All About Cookies.
            <br/><br/>
            </Typography>
          

            <Typography variant="h3">Use of Your Personal Data
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            The Company may use Personal Data for the following purposes:
            <br/><br/>
            To provide and maintain our Service, including to monitor the usage of our Service.
            <br/><br/>
            For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
            <br/><br/>
            To contact You: To contact You by email, telephone calls or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
            <br/><br/>
            To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
            <br/><br/>
            To manage Your requests: To attend and manage Your requests to Us.
            <br/><br/>
            </Typography>

            <Typography variant="h3">Retention of Your Personal Data
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
            <br/><br/>
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            <br/><br/>
            </Typography>

            <Typography variant="h3">Disclosure of Your Personal Data
            <br/><br/>
            </Typography>

            <Typography variant="h4">Law enforcement<br/><br/>
            </Typography>

            <Typography variant="body1">
            Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            <br/><br/>
            </Typography>

            <Typography variant="h4">Other legal requirements
            <br/><br/>
            </Typography>


            <Typography variant="body1">
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            <List>
                <ListItem>
                    <ListItemText primary={"Comply with a legal obligation"} />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"Protect and defend the rights or property of the Company"}/>
                </ListItem>

                <ListItem>
                    <ListItemText primary={"Prevent or investigate possible wrongdoing in connection with the Service"} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Protect the personal safety of Users of the Service or the public" />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Protect against legal liability" />
                </ListItem>

            </List>
            <br/><br/>


            </Typography>

            <Typography variant="h3">Security of Your Personal Data<br/><br/>
            </Typography>

            <Typography variant="body1">
            We do not use vulnerability scanning and/or scanning to PCI standards.
            <br/><br/>
            Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.
            <br/><br/>
            All sensitive/credit information you supply is encrypted.
            <br/><br/>
            </Typography>
            
            <Typography variant="h3">Links to Other Websites
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
            <br/><br/>
            We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            <br/><br/>
            </Typography>


            <Typography variant="h3">Changes to this Privacy Policy
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
            <br/><br/>
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
            <br/><br/>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            <br/><br/>
            </Typography>

            <Typography variant="h2">Contact Us
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            If you have any questions about this Privacy Policy, You can contact us by email: operations@bitlendr.io
            <br/><br/>
            </Typography>



        </Container>


    );
};

export default Policy;
