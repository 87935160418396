import React from 'react';
import { List, ListItem, ListItemText, Container, Typography } from '@mui/material'; // Assuming you're using MUI components

const Terms = () => {
    return (
        <Container>
            <Typography variant="h2">Terms and Conditions</Typography>

            <Typography variant="subtitle1" paragraph>
            Last updated: October 7, 2024<br/><br/>
            </Typography>

            <Typography variant="h3">Interpretation and Definitions<br/><br/>
            </Typography>

            <Typography variant="h4">Interpretation<br/><br/>
            </Typography>

            <Typography variant="body1">
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.<br/><br/>
            </Typography>

            <Typography variant="h4">Definitions<br/><br/>
            </Typography>

            <Typography variant="body1">
            For the purposes of these Terms and Conditions:
            <List>
                <ListItem>
                    <ListItemText primary="Country refers to: Romania" />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"Company (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to SC BorrowBit SRL"}/>
                </ListItem>

                <ListItem>
                    <ListItemText primary="Device means any device that can access the Service such as a computer, a cellphone or a digital tablet." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Service refers to the Website (i.e., www.bitlendr.io)." />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"Terms and Conditions (also referred as \"Terms\") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service."} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Third-party Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Website refers to Bitlendr, accessible from bitlendr.io" />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Interest means the indicated interest rate accrued in accordance with these Terms and subject to revision at any time." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Intellectual Property means any intellectual and industrial property rights, belonging to Bitlendr, for example, trademarks, service marks, logos, copyright and related rights, know-how, research, publications, agreements, trade and company names, etc" />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Digital Assets means any digital assets (such as cryptocurrencies, stablecoins and tokenized assets), accepted by Bitlendr." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="You or Client means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="Loan means any loan facility granted by Bitlendr and the total amount of the loan due by the Client to Bitlendr at any time until its full repayment, including the principal, the Interest and any fees due to Bitlendr under this Agreement." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="The annual percentage rate (hereinafter “the APR”) is the interest rate of return earned." />
                </ListItem>

                <ListItem>
                        <ListItemText primary={"Interest (referred to as either \“Reward\” or \“APR\” in this Agreement) means the indicated interest rate accrued in accordance with these Terms and subject to revision at any time."} />
                </ListItem>

                <ListItem>
                        <ListItemText primary="Active Deposit - it is a Deposit that contains at least the Minimum amount of Digital Assets." />
                </ListItem>
            </List>
            </Typography>
            <Typography variant="h3">Acknowledgment<br/><br/>
            </Typography>

            <Typography variant="body1">
            These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
            <br/><br/>
            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
            <br/><br/>
            By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You should not access the Service.
            <br/><br/>
            By using the Service, You acknowledge that you are over the age of 18. The Company does not permit those under 18 to use the Service.
            <br/><br/>
            By using the service, You acknowledge that you are not a citizen or resident of the United States of America, People’s Republic of China or of a sanctioned country according to the up-to-date lists of the US Office of Foreign Assets Control (OFAC), the United Nations, the European Union and any EU Member State, the UK Treasury, etc.;
            <br/><br/>
            Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
            <br/><br/>
            By entering into this Agreement, you acknowledge and confirm that you meet all the conditions set out above. In the event that we subsequently ascertain that you have not met or does not meet any of these conditions anymore, we will suspend the provision of the Bitlendr services to you, and do not allow you to use the Bitlendr services in the future.
            <br/><br/>
            You are aware that you use the Bitlendr and the Loan fully at your own risk.
            <br/><br/>
            </Typography>
            <Typography variant="h3">Links to Other Websites<br/><br/>
            </Typography>

            <Typography variant="body1">
            Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. 
            <br/><br/>
            The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
            <br/><br/>
            We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
            <br/><br/>
            </Typography>

            <Typography variant="h3">Termination<br/><br/>
            </Typography>

            <Typography variant="body1">
            We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions. 
            <br/><br/>
            Upon termination, Your right to use the Service will cease immediately.
            <br/><br/>
            </Typography>

            <Typography variant="h3">Limitation of Liability<br/><br/>
            </Typography>

            <Typography variant="body1">
            To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
            <br/><br/>
            </Typography>

            <Typography variant="h3">"AS IS" and "AS AVAILABLE"<br/><br/>
            </Typography>
            <Typography variant="h4">Disclaimer<br/><br/>
            </Typography>

            <Typography variant="body1">
            The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
            <br/><br/>
            Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.
            <br/><br/>
            Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
            <br/><br/>
            </Typography>


            <Typography variant="body1">
            Bitlendr APR (Annual Percentage Rate), Collateral, Repayment and Withdrawal:
            <List>
                <ListItem>
                    <ListItemText primary={"1. Bitlendr will grant you a Loan in, if you provide the required Digital Assets as collateral by transferring them into the Bitlendr Service (\“Collateral\”). All such Digital Assets are indicated on the Bitlendr Website and are subject to revision from time to time."} />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"2. Unless prohibited by any Applicable Law, by virtue of this Agreement Bitlendr acquired the ownership of the Collateral while the Loan is outstanding."}/>
                </ListItem>

                <ListItem>
                    <ListItemText primary={"3. A compound annual Interest shall commence accruing as of the start date of the Loan (\“Start Date\”), at the rate determined at the time the Loan is granted and indicated on the Bitlendr service."} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="4. The Interest shall be subject to revision from time to time, at our sole and absolute discretion. You will be informed by any changes to the Interest with due notice of at least 5 working days." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="5. The Interest shall be calculated on a weekly basis." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="6. No periodic repayment of the Interest shall be due." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="7. Annual interest rate depends on LTV that you've chosen while getting a loan and may vary due to the market conditions." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="8. There is no maturity date for loan. It can be repaid at any Date." />
                </ListItem>

                <ListItem>
                    <ListItemText primary="9. You may repay at any time by transferring into the Bitlendr service the same fiat amount as the loan granted, plus the accrued Interest. Certain rules may apply to repayments from time to time, as indicated on the Bitlendr service." />
                </ListItem>

                <ListItem>
                    <ListItemText primary={"10. Once the loan has been repaid in full, all remaining Collateral shall be transferred back to the Client/’s address. Bitlendr charges processing fee on withdrawal operations."} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="11. Bitlendr may provide you with discounts on the loan or any part thereof, such as the principal or the Interest, at any time, under conditions it finds appropriate."/>
                </ListItem>

                <ListItem>
                        <ListItemText primary={"12. Each loan has an individual calculation of the Warning Price and the Margin Price. The Warning Price is the price of the underlying collateral at which the Loan-to-Value equals 90%. If Your underlying collateral reaches the Warning Price, we will inform you about it and ask you to top-up or close the loan. If Your underlying collateral reaches the Margin Price, we will liquidate the underlying collateral."} />
                </ListItem>

                <ListItem>
                        <ListItemText primary="13. When taking out a loan, you agree to the condition of liquidation of your collateral when the collateral currency exchange rate reaches the Margin Price." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="14. The entry Margin Price for the exact loan is shared via email once the loan has been originated." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="15. Accumulating interest on a loan increases the loan Margin Price." />
                </ListItem>

                <ListItem>
                        <ListItemText primary="16. The Margin Price is displayed in the personal account of your loan
" />
                </ListItem>

                <ListItem>
                        <ListItemText primary="17. The rate of the collateral currency is compared every second with the currency rate of liquidity partner which provides trading pairs for this currency. If at some point it drops to the liquidation rate or lower, Bitlendr has the right to automatically sell the collateral." />
                </ListItem>

                <ListItem>
                        <ListItemText primary={"18. Due to the volatile nature of the Digital Assets market, it may be technically impossible for Bitlendr to notify you about risk zones or liquidation (although the Company will make every effort to do so), and that you are solely responsible for monitoring the current market conditions and maintaining the price of your collateral in full accordance with these Terms and Conditions."}/>
                </ListItem>

                <ListItem>
                        <ListItemText primary="19. Bitlendr utilizes thorough market analysis to adjust interest rates based on current conditions. We're agile in responding to market changes, so rates for new loans can be changed regularly to match market fluctuations." />
                </ListItem>
                
                <ListItem>
                        <ListItemText primary={"20. Current information on the interest rates for new loans is displayed on the main page of the service – bitlendr.io while selecting loan terms."} />
                </ListItem>

            </List>
            <br/><br/>
            </Typography>

            <Typography variant="h3">Governing Law<br/><br/>
            </Typography>

            <Typography variant="body1">
            The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
            <br/><br/>
            </Typography>
            
            <Typography variant="h3">Disputes Resolution<br/><br/>
            </Typography>

            <Typography variant="body1">
            If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
            <br/><br/>
            </Typography>

            <Typography variant="h2">Severability and Waiver
            <br/><br/>
            </Typography>

            <Typography variant="h3">Severability<br/><br/>
            </Typography>

            <Typography variant="body1">
            If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
            <br/><br/>
            </Typography>

            <Typography variant="h3">Waiver<br/><br/>
            </Typography>

            <Typography variant="body1">
            Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
            <br/><br/>
            </Typography>

            <Typography variant="h2">Translation Interpretation
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
            <br/><br/>
            </Typography>

            <Typography variant="h2">Changes to These Terms and Conditions
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
            <br/><br/>
            </Typography>

            <Typography variant="h2">Contact Us
            <br/><br/>
            </Typography>

            <Typography variant="body1">
            If you have any questions about these Terms and Conditions, You can contact us by email: operations@bitlendr.io
            <br/><br/>
            </Typography>



        </Container>


    );
};

export default Terms;
