import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";

interface BoxContent {
    imageUrl?: string;
    title: string;
    paragraphText: string;
}

interface LandingPageBoxProps extends BoxContent {
    isMobile: boolean;
    currentWidth: number;
}

interface LandingPageBoxesPageProps {
    title: string;
    boxContents: BoxContent[];
}

const LandingPageBox  = ({imageUrl, title, paragraphText, isMobile, currentWidth}: LandingPageBoxProps) => {
    return (
        <Box
            sx={{
                overflowWrap: 'anywhere',
                background: '#7CA5FF',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                padding: '10px',
                minHeight: currentWidth >= 1945 ? '190px' :  currentWidth >= 1600 ? '210px' : currentWidth >= 1400 ? '230px' : currentWidth >= 1200 ? '254px' : currentWidth >= 960 ? '190px' : currentWidth >= 840 ? '150px' : '90px',
                height: 'fit-content'
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignContent="center"
                width="100%"
                minHeight={isMobile ? '60px' : '72px'}
                height="fit-content"
            >
                {
                    imageUrl ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <img
                                alt={title}
                                src={imageUrl}
                                width="30px"
                                height="30px"
                            />
                        </Box>
                    ) : null
                }
                <Typography
                    fontSize={isMobile ? 'large' : 'x-large'}
                    align="center"
                    alignContent="center"
                    sx={{
                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        color: 'white',
                        width: '80%'
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                mt={isMobile ? 0 : 2}
            >
                <Typography
                    fontSize={isMobile ? 'small' : 'medium'}
                    align="left"
                    sx={{
                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        color: 'white',
                    }}
                >
                    {paragraphText}
                </Typography>
            </Box>
        </Box>
    );
};

const LandingPageBoxGridItem  = ({imageUrl, title, paragraphText, isMobile, currentWidth}: LandingPageBoxProps) => {
    return (
        <Grid
            size={{
                sm: 12,
                md: 6,
                lg: 3,
            }}
        >
            <LandingPageBox
                imageUrl={imageUrl}
                title={title}
                paragraphText={paragraphText}
                isMobile={isMobile}
                currentWidth={currentWidth}
            />
        </Grid>
    );
};

export const LandingPageBoxesPage = ({title, boxContents}: LandingPageBoxesPageProps) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setCurrentWidth(window.innerWidth);
            setIsMobile(window.innerWidth <= 840);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="100%"
            minHeight="100vh"
            height="fit-contet"
            sx={{
                backgroundColor: '#E6ECFB'
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
            >
                <Typography
                    alignSelf="center"
                    fontSize={isMobile ? 'xx-large' : 'xxx-large'}
                    align="center"
                    sx={{
                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        justifyContent: 'center'
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                paddingTop={isMobile ? 2 : 4}
                paddingBottom={isMobile ? 4 : 1}
            >
                <Grid container spacing={2} width="95%">
                    {
                        boxContents.map((boxContent) => (
                            <LandingPageBoxGridItem
                                imageUrl={boxContent.imageUrl}
                                title={boxContent.title}
                                paragraphText={boxContent.paragraphText}
                                isMobile={isMobile}
                                currentWidth={currentWidth}
                            />
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    );
};