import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {BallsAnimation} from "../../animations/components/BallsAnimation";
import {throttle} from "../../utils/utils";
import {extractSupportedCryptosList, ListCryptoPriceQuotesResponse} from "../../data/loanMetrics";
import {listCryptoPriceQuotes} from "../../graphql/queries";
import {generateClient} from "aws-amplify/api";

const CRYPTO_NAMES: {[coinName: string]: string} = {
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    XRP: 'Ripple',
    ADA: 'Cardano',
    DOT: 'Polkadot',
    SOL: 'Solana',
};

const CRYPTO_IMAGE_OVERRIDES: {[coinName: string]: string} = {
    DOT: `https://content-api.changenow.io/uploads/dot_c006c687e7.svg`,
};

const client = generateClient();

interface SupportedCoinBoxBoxProps {
    coinIdentifier: string;
    coinName: string;
    coinClickedHandler: (coinIdentifier: string) => void;
}
const SupportedCoinBox = ({ coinIdentifier, coinName, coinClickedHandler }: SupportedCoinBoxBoxProps) => {
    const onClickHandler = () => {
        coinClickedHandler(coinIdentifier);
    };

    return (
        <Box
            sx={{
                overflowWrap: 'anywhere',
                background: '#7CA5FF',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                padding: '10px',
                minHeight: '270ps',
                '&:hover': {
                    transform: 'scale(1.05)', // Slightly enlarge on hover
                    background: '#96B7FF'
                },
            }}
            onClick={onClickHandler}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
            >
                <img
                    alt={coinIdentifier}
                    src={CRYPTO_IMAGE_OVERRIDES[coinIdentifier] ?? `https://changenow.io/images/sprite/currencies/${coinIdentifier.toLowerCase()}.svg`}
                />
                <Typography
                    ml={1}
                    fontSize="large"
                    align="center"
                    sx={{
                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        color: 'white',
                    }}
                >
                    {coinName}
                </Typography>
                <Typography
                    ml={1}
                    fontSize="medium"
                    align="center"
                    sx={{
                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        color: 'gray',
                    }}
                >
                    {coinIdentifier}
                </Typography>
            </Box>
        </Box>
    );
};
const SupportedCoinGridItem = ({ coinIdentifier, coinName, coinClickedHandler }: SupportedCoinBoxBoxProps) => {
    return (
        <Grid
            size={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
                xl: 2
            }}
        >
            <SupportedCoinBox
                coinIdentifier={coinIdentifier}
                coinName={coinName}
                coinClickedHandler={coinClickedHandler}
            />
        </Grid>
    );
};

interface LandingPageBallsAnimationProps {
    setSelectedCrypto: (selectedCrypto: string)=> void;
}
export const LandingPageBallsAnimation = ({setSelectedCrypto}: LandingPageBallsAnimationProps) => {
    const [supportedCryptos, setSupportedCryptos] = useState<string[]>([]);
    const loadData = useCallback(async () => {
        try {
            const cryptoPriceQuotesResponse: ListCryptoPriceQuotesResponse = await client.graphql({
                query: listCryptoPriceQuotes,
                authMode: 'apiKey'
            }) as ListCryptoPriceQuotesResponse;
            setSupportedCryptos(extractSupportedCryptosList(cryptoPriceQuotesResponse.data.listCryptoPriceQuotes.items));
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }
    }, []);
    useEffect(() => {
        loadData();
    }, []);

    const supportedCoinClickedHandler = (coinIdentifier: string): void => {
        setSelectedCrypto(coinIdentifier);

        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }, 0);
    };

    const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 840);
    const throttledSetCurrentWidth = useCallback(throttle(() => {
        setCurrentWidth(window.innerWidth);
        setIsMobile(window.innerWidth <= 840);
    }, 650), [setCurrentWidth]);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth !== currentWidth) {
                throttledSetCurrentWidth();
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const threejsContainerRef = useRef(null);
    BallsAnimation(threejsContainerRef, currentWidth);

    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="column"
            width="100%"
            minHeight="100vh"
            height="fit-contet"
            sx={{
                backgroundColor: '#E6ECFB'
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignContent="center"
                width="100%"
                height="100%"
                sx={{
                    backgroundColor: '#E6ECFB',
                }}
                ref={threejsContainerRef}
            />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width="100%"
                height="100%"
                position="absolute"
                zIndex={1}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    width="100%"
                >
                    <Typography
                        alignSelf="center"
                        fontSize="xxx-large"
                        align="center"
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            justifyContent: 'center'
                        }}
                    >
                        We accept all major crypto assets.
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    width="100%"
                    paddingTop={isMobile ? 2 : 4}
                >
                    <Grid container spacing={2} width="70%">
                        {
                            Object.keys(CRYPTO_NAMES)
                                .filter(cryptoName => supportedCryptos.includes(cryptoName))
                                .map((supportedCrypto) => (
                                    <SupportedCoinGridItem
                                        coinIdentifier={supportedCrypto}
                                        coinName={CRYPTO_NAMES[supportedCrypto]}
                                        coinClickedHandler={supportedCoinClickedHandler}
                                    />
                                ))
                        }
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};