/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLoan = /* GraphQL */ `
  mutation CreateLoan(
    $input: CreateLoanInput!
    $condition: ModelLoanConditionInput
  ) {
    createLoan(input: $input, condition: $condition) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      approvedAt
      settlementWalletAddress
      settlementWalletNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLoan = /* GraphQL */ `
  mutation UpdateLoan(
    $input: UpdateLoanInput!
    $condition: ModelLoanConditionInput
  ) {
    updateLoan(input: $input, condition: $condition) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      approvedAt
      settlementWalletAddress
      settlementWalletNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLoan = /* GraphQL */ `
  mutation DeleteLoan(
    $input: DeleteLoanInput!
    $condition: ModelLoanConditionInput
  ) {
    deleteLoan(input: $input, condition: $condition) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      approvedAt
      settlementWalletAddress
      settlementWalletNetwork
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet(
    $input: CreateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    createWallet(input: $input, condition: $condition) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWallet = /* GraphQL */ `
  mutation UpdateWallet(
    $input: UpdateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    updateWallet(input: $input, condition: $condition) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWallet = /* GraphQL */ `
  mutation DeleteWallet(
    $input: DeleteWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    deleteWallet(input: $input, condition: $condition) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCryptoPriceQuote = /* GraphQL */ `
  mutation CreateCryptoPriceQuote(
    $input: CreateCryptoPriceQuoteInput!
    $condition: ModelCryptoPriceQuoteConditionInput
  ) {
    createCryptoPriceQuote(input: $input, condition: $condition) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCryptoPriceQuote = /* GraphQL */ `
  mutation UpdateCryptoPriceQuote(
    $input: UpdateCryptoPriceQuoteInput!
    $condition: ModelCryptoPriceQuoteConditionInput
  ) {
    updateCryptoPriceQuote(input: $input, condition: $condition) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCryptoPriceQuote = /* GraphQL */ `
  mutation DeleteCryptoPriceQuote(
    $input: DeleteCryptoPriceQuoteInput!
    $condition: ModelCryptoPriceQuoteConditionInput
  ) {
    deleteCryptoPriceQuote(input: $input, condition: $condition) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCurrencyExchangeRate = /* GraphQL */ `
  mutation CreateCurrencyExchangeRate(
    $input: CreateCurrencyExchangeRateInput!
    $condition: ModelCurrencyExchangeRateConditionInput
  ) {
    createCurrencyExchangeRate(input: $input, condition: $condition) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCurrencyExchangeRate = /* GraphQL */ `
  mutation UpdateCurrencyExchangeRate(
    $input: UpdateCurrencyExchangeRateInput!
    $condition: ModelCurrencyExchangeRateConditionInput
  ) {
    updateCurrencyExchangeRate(input: $input, condition: $condition) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCurrencyExchangeRate = /* GraphQL */ `
  mutation DeleteCurrencyExchangeRate(
    $input: DeleteCurrencyExchangeRateInput!
    $condition: ModelCurrencyExchangeRateConditionInput
  ) {
    deleteCurrencyExchangeRate(input: $input, condition: $condition) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShareableCode = /* GraphQL */ `
  mutation CreateShareableCode(
    $input: CreateShareableCodeInput!
    $condition: ModelShareableCodeConditionInput
  ) {
    createShareableCode(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateShareableCode = /* GraphQL */ `
  mutation UpdateShareableCode(
    $input: UpdateShareableCodeInput!
    $condition: ModelShareableCodeConditionInput
  ) {
    updateShareableCode(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteShareableCode = /* GraphQL */ `
  mutation DeleteShareableCode(
    $input: DeleteShareableCodeInput!
    $condition: ModelShareableCodeConditionInput
  ) {
    deleteShareableCode(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSettlementBankAccount = /* GraphQL */ `
  mutation CreateSettlementBankAccount(
    $input: CreateSettlementBankAccountInput!
    $condition: ModelSettlementBankAccountConditionInput
  ) {
    createSettlementBankAccount(input: $input, condition: $condition) {
      id
      currency
      name
      iban
      swift
      address
      source
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSettlementBankAccount = /* GraphQL */ `
  mutation UpdateSettlementBankAccount(
    $input: UpdateSettlementBankAccountInput!
    $condition: ModelSettlementBankAccountConditionInput
  ) {
    updateSettlementBankAccount(input: $input, condition: $condition) {
      id
      currency
      name
      iban
      swift
      address
      source
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSettlementBankAccount = /* GraphQL */ `
  mutation DeleteSettlementBankAccount(
    $input: DeleteSettlementBankAccountInput!
    $condition: ModelSettlementBankAccountConditionInput
  ) {
    deleteSettlementBankAccount(input: $input, condition: $condition) {
      id
      currency
      name
      iban
      swift
      address
      source
      createdAt
      updatedAt
      __typename
    }
  }
`;
