import {Box, Typography} from "@mui/material";
import React from "react";
import {DesktopLoanCalculator} from "../../components/loanCalculator/DesktopLoanCalculator";

interface LandingPageSecondCalculatorProps {
    selectedCrypto: string;
    setSelectedCrypto: (selectedCrypto: string)=> void;
}

export const LandingPageSecondCalculator = ({selectedCrypto, setSelectedCrypto}: LandingPageSecondCalculatorProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100vh"
            sx={{
                backgroundColor: '#E6ECFB'
            }}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                width="100%"
                height="100%"
                sx={{backgroundColor: '#E6ECFB'}}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Typography
                        fontSize="xxx-large"
                        align="center"
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            justifyContent: 'center'
                        }}
                    >
                        What you see <br/> is what you get.
                    </Typography>
                    <DesktopLoanCalculator
                        inputFieldBackgroundColorOverride="#FAFAFA"
                        iconColorOverride="#1B2030"
                        sliderColorOverride="#1B2030"
                        sliderTextColorOverride="#1B2030"
                        buttonTextOverride='Get your loan now →'
                        selectedCrypto={selectedCrypto}
                        setSelectedCrypto={setSelectedCrypto}
                    />
                </Box>
            </Box>
        </Box>
    );
};