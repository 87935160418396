import {Box, Divider} from "@mui/material";
import React from "react";

export const CardVerticalDividerRow = () => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            width="95%"
        >
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <Divider />
            </Box>
        </Box>
    );
};