import React, {ChangeEvent, useCallback, useState} from 'react';
import {Status} from '../types';
import {Box, Button, Grid, Typography} from '@mui/material';
import {LoanStatus} from './LoanStatus';
import {generateClient} from 'aws-amplify/api';
import {updateLoan} from '../../graphql/mutations';
import {SettlementBankAccount} from '../../data/bankAccounts';
import {CardTextInputField} from "../../baseComponents/cardElements/CardTextInputField";
import {CardKeyValueRow} from "../../baseComponents/cardElements/CardKeyValueRow";
import {CardSubtitleRow} from "../../baseComponents/cardElements/CardSubtitleRow";
import {CardVerticalDividerRow} from "../../baseComponents/cardElements/CardVerticalDividerRow";

const client = generateClient();

export interface LoanListItemProps {
    id: string;
    date: string;
    loanAmount: number;
    loanCurrency: string;
    collateralAmount: number;
    collateralCryptoCurrency: string;
    interestAmount: number;
    interestCurrency: string;
    status: Status;
    loanWalletAddress?: string;
    loanWalletPublicKey?: string;
    isMobile: boolean;
    onCloseCallback: () => void;
    settlementBankAccount: SettlementBankAccount | null
}

const statusColors: {[key: string]: string} = {
    [Status.PENDING]: '#7C7C7C',
    [Status.APPROVED]: '#0552FF',
    [Status.REJECTED]: '#E27171',
    [Status.CLOSING]: '#0552FF',
    [Status.CLOSED]: '#E71C38',
};
export const LoanListItem = ({
    id,
    date,
    loanAmount,
    loanCurrency,
    collateralAmount,
    collateralCryptoCurrency,
    interestAmount,
    interestCurrency,
    status,
    loanWalletAddress,
    loanWalletPublicKey,
    isMobile,
    onCloseCallback,
    settlementBankAccount,
}: LoanListItemProps) => {
    const [settlementWalletAddress, setSettlementWalletAddress] = useState<string>("");
    const [settlementWalletNetwork, setSettlementWalletNetwork] = useState<string>("");
    const [isSubmittingClosingRequest, setIsSubmittingClosingRequest] = useState<boolean>(false);

    const handleWalletAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setSettlementWalletAddress(value);
    };
    const handleWalletNetworkChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setSettlementWalletNetwork(value);
    };

    const closeButtonHandler = useCallback(async () => {
        if (!isSubmittingClosingRequest) {
            setIsSubmittingClosingRequest(true);
        } else if (settlementWalletAddress !== "") {
            try {
                const updateResponse = await client.graphql({
                    query: updateLoan,
                    variables: {
                        input: {
                            id,
                            status: Status.CLOSING,
                            settlementWalletAddress,
                            ...(settlementWalletNetwork !== "" && { settlementWalletNetwork })
                        }
                    },
                    authMode: "userPool"
                });
                console.log(`Update response was: [${JSON.stringify(updateResponse)}].`)
                onCloseCallback();
            } catch (error) {
                console.error(`Error occurred while trying to close the loan: ${id}. Error: [${JSON.stringify(error)}]`)
            }
        }
    }, [id, isSubmittingClosingRequest, settlementWalletAddress, settlementWalletNetwork]);

    return (
        <Grid item xs={isMobile ? 11 : 6}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
                sx={{
                    background: '#FAFAFA',
                    padding: '20px',
                    borderRadius: '20px',
                }}
            >
                <Grid container>
                    <Grid item xs={5}>
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Box display="flex" flexDirection="column">
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    Number
                                </Typography>
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    width="100%"
                                    overflow="hidden"
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    {id}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <LoanStatus status={status} isMobile={isMobile}/>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            display="flex"
                            justifyContent="right"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    {new Date(date).toLocaleDateString()}
                                </Typography>
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    alignSelf="flex-end"
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    {new Date(date).toLocaleTimeString()}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={isMobile ? 2 : 4} mb={isMobile ? 0 : 1} >
                    <Typography
                        alignContent="center"
                        justifyContent="center"
                        color="#1B2030"
                        fontSize={isMobile ? 'x-large' : 'xx-large'}
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        }}
                    >
                        {collateralAmount} {collateralCryptoCurrency}
                    </Typography>
                </Box>

                <Box mb={isMobile ? 1 : 2} >
                    <Typography
                        alignContent="center"
                        justifyContent="center"
                        color={statusColors[status] ?? '#FAFAFA'}
                        fontSize={isMobile ? 'x-large' : 'xx-large'}
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        }}
                    >
                        {loanAmount} {loanCurrency}
                    </Typography>
                </Box>


                {
                    status === Status.APPROVED
                        ? isSubmittingClosingRequest
                            ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginTop="10px"
                                    width="100%"
                                >
                                    <CardVerticalDividerRow />

                                    <CardSubtitleRow
                                        titleText="Where should we return your crypto?"
                                        marginTop={1}
                                    />

                                    <CardTextInputField
                                        inputFieldFormId="settlementWalletAddressInput"
                                        labelText="Wallet Address*"
                                        inputValue={settlementWalletAddress}
                                        inputChangeHandler={handleWalletAddressChange}
                                    />

                                    <CardTextInputField
                                        inputFieldFormId="settlementWalletNetworkInput"
                                        labelText="Wallet Network"
                                        inputValue={settlementWalletNetwork}
                                        inputChangeHandler={handleWalletNetworkChange}
                                    />
                                </Box>
                            )
                            : (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginTop="10px"
                                    width="100%"
                                >
                                    <CardVerticalDividerRow />

                                    <CardSubtitleRow
                                        titleText="Interest information"
                                        marginTop={1}
                                    />

                                    <CardKeyValueRow
                                        keyText="Interest Rate"
                                        valueText="8%"
                                        marginTop={2}
                                    />

                                    <CardKeyValueRow
                                        keyText="Accumulated Interest"
                                        valueText={`${interestAmount} ${interestCurrency}`}
                                        marginTop={1}
                                    />
                                </Box>
                            )
                        : null
                }

                {
                    status === Status.CLOSING && settlementBankAccount !== null ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            marginTop="10px"
                            width="100%"
                        >
                            <CardVerticalDividerRow />

                            <CardSubtitleRow
                                titleText="Settlement Payment Details"
                                marginTop={2}
                            />

                            <CardKeyValueRow
                                keyText="AMOUNT"
                                valueText={`${loanAmount + interestAmount} ${loanCurrency}`}
                                marginTop={2}
                            />

                            <CardKeyValueRow
                                keyText="IBAN"
                                valueText={settlementBankAccount.iban}
                                marginTop={2}
                            />

                            <CardKeyValueRow
                                keyText="NAME"
                                valueText={settlementBankAccount.name}
                                marginTop={2}
                            />

                            <CardKeyValueRow
                                keyText="ADDRESS"
                                valueText={settlementBankAccount.address}
                                marginTop={2}
                            />

                            <CardKeyValueRow
                                keyText="SWIFT"
                                valueText={settlementBankAccount.swift}
                                marginTop={2}
                            />
                        </Box>
                    ) : null
                }

                <Box
                    mt={isMobile ? 2 : 4}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                    >
                        <Typography
                            alignContent="left"
                            justifyContent="left"
                            color="#9D9D9D"
                            fontSize={isMobile ? 'xx-small' : 'x-small'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            DEPOSIT ADDRESS
                        </Typography>
                        <Typography
                            alignContent="left"
                            justifyContent="left"
                            color="#1B2030"
                            fontSize={isMobile ? 'xx-small' : 'x-small'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            {loanWalletAddress}
                        </Typography>
                    </Box>
                    {
                        status === Status.APPROVED ? (
                            <Button
                                size="large"
                                onClick={closeButtonHandler}
                                variant="contained"
                                sx={{
                                    height: '28px',
                                    borderRadius: '50px',
                                    backgroundColor: isMobile ? '#1a202c' : '#0752FF',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'none',
                                }}>
                                {isSubmittingClosingRequest ? 'Submit' : 'Close loan'}
                            </Button>
                        ) : null
                    }
                </Box>
            </Box>
        </Grid>
    );
};