import {Box, Typography} from "@mui/material";
import React from "react";

interface KeyValueRowProps {
    keyText: string;
    valueText: string;
    marginTop?: number;
}

export const CardKeyValueRow = ({keyText, valueText, marginTop = 0}: KeyValueRowProps) => {
    return (
        <Box
            mt={marginTop}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="90%"
        >
            <Typography
                color="#9D9D9D"
                fontSize='x-small'
                sx={{
                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                }}
            >
                {keyText}
            </Typography>
            <Typography
                color="#9D9D9D"
                fontSize="x-small"
                fontWeight="bold"
                align="right"
                sx={{
                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                }}
            >
                {valueText}
            </Typography>
        </Box>
    );
};