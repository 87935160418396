import {Box, FormControl, Input, Typography} from "@mui/material";
import React, {ChangeEvent} from "react";

interface TextInputFieldProps {
    inputFieldBackgroundColor?: string;
    inputFieldFormId: string;
    inputValue: string;
    inputChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    labelText: string;
    labelTextColour?: string;
}

export const CardTextInputField = ({
    inputFieldFormId,
    inputFieldBackgroundColor,
    inputValue,
    inputChangeHandler,
    labelText,
    labelTextColour,
}: TextInputFieldProps) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            width="90%"
        >
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <FormControl
                    variant="filled"
                    sx={{
                        width: '100%',
                        borderRadius: '10px',
                        border: 'solid 1px #9D9D9D',
                        backgroundColor: inputFieldBackgroundColor ?? '#FFFFFF',
                        marginTop: '20px',
                    }}
                >

                    <Typography
                        justifyContent="left"
                        color={labelTextColour ?? '#9D9D9D'}
                        fontSize='small'
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            margin: '-13px 0 0 13px',
                            borderRadius: '10px',
                            background: '#FFFFFF',
                            padding: '3px',
                            display: 'inline-block',
                            width: 'fit-content'
                        }}
                    >
                        {labelText}
                    </Typography>
                    <Box display="flex">
                        <Input
                            id={inputFieldFormId}
                            type="text"
                            value={inputValue}
                            disableUnderline
                            onChange={inputChangeHandler}
                            sx={{
                                flex: 1,
                                marginLeft: '14px',
                                '.MuiInputBase-input': {
                                    backgroundColor: inputFieldBackgroundColor ?? '',
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                },
                            }}
                        />
                    </Box>
                </FormControl>
            </Box>
        </Box>
    );
};