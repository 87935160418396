import React from 'react';
import Background from '../images/Bitlendr_transparent.svg'

const styles = {
    footer: {
        padding: '20px',
        backgroundColor: '#E6ECFB',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    columnsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: "5%"
    },
    column: {
        flex: 1,
        margin: '0 10px',
    },
    title: {
        color: 'blue',
        marginBottom: '10px',
    },
    link: {
        display: 'block',
        color: 'black',
        textDecoration: 'none',
        marginBottom: '5px',
        cursor: 'pointer',
    },
    copyright: {
        paddingTop: '10px',
        marginLeft: '10%'
    },
};

export const Footer = () => {
     // Define the URL mapping
     const linkUrls = {
        "Login": "/home",
        "Feature Requests": "https://forms.gle/hVEfyFoKLye8Sr558",
        "Status: Online": "/status",
        "FAQ": "/faq",
        "Contact Us": "https://forms.gle/DgXD3pbzU89bDYvj6",
        "About": "/about",
        "Terms & Conditions": "/terms",
        "Privacy Policy": "/policy",
        "Instagram": "https://www.instagram.com",
        "Telegram": "https://t.me",
        "Discord": "https://discord.com",
        "Linkedin": "https://www.linkedin.com",
        "Tik-Tok": "https://www.tiktok.com"
    } as const;

    const columns = [
        {
            title: "App",
            links: ["Login", "Feature Requests"]
        },
        {
            title: "Help",
            links: ["Contact Us"]
        },
        {
            title: "Legal",
            links: ["Terms & Conditions", "Privacy Policy"]
        },
        {
            title: "Social",
            links: ["Instagram", "Telegram", "Discord", "Linkedin", "Tik-Tok"]
        },
    ];

    return (
        <div style={styles.footer}>
            <div
                // @ts-ignore
                style={styles.columnsContainer}
            >
                {columns.map((column, idx) => (
                    // <div key={idx} style={styles.column}>
                    //     <h6 style={styles.title}>{column.title}</h6>
                    //     {column.links.map((link, linkIdx) => (
                    //         <a key={linkIdx} href="#" style={styles.link}>{link}</a>
                    //     ))}
                    // </div>
                    <div key={idx} style={styles.column}>
                        <h6 style={styles.title}>{column.title}</h6>
                        {column.links.map((link, linkIdx) => (  // Corrected to add linkIdx here
                            <a
                                key={linkIdx}  // linkIdx used correctly now
                                href={linkUrls[link as keyof typeof linkUrls]} // Dynamically assign URL
                                target={linkUrls[link as keyof typeof linkUrls].startsWith('http') ? '_blank' : '_self'} // Open external links in a new tab
                                rel={linkUrls[link as keyof typeof linkUrls].startsWith('http') ? 'noopener noreferrer' : ''} // Ensure security for external links
                                style={styles.link}
                            >
                                {link}
                            </a>
                        ))}
                    </div>
                ))}
            </div>
            <div style={styles.copyright}>
                Copyright © 2023 Bitlendr.io. All rights reserved.
            </div>
        </div>
    );
};
